import React from "react";
import { useStaticQuery, graphql } from 'gatsby';

import SEO from "../components/seo";
import Info from '../containers/Info';
import FaqSection from '../containers/FaqSection';
import Testimonial from '../containers/Testimonial';
import WorkSection from '../containers/WorkSection';
import CallToAction from '../containers/CallToAction';
import PartnerSection from '../containers/PartnerSection';

const Solutions = () => {

  const data = useStaticQuery(graphql`
    query {
      dataJson {
        WORKDATA {
          solutions {
            id
            title
            description
            icon {
              publicURL
            }
          }
        }
        SOLUTIONS {
          seo {
            title
            description
            url
            imageAlt
            twitterProfile
            image {
              publicURL
            }
            twiterImage {
              publicURL
            }
            keywords
          }
        }
      }
    }
  `);

  const { solutions } = data.dataJson.WORKDATA;
  const { seo } = data.dataJson.SOLUTIONS;
  
  return (
    <>
      <SEO metaData={seo} lang="" />
      <Info />
      <WorkSection items={solutions} />
      <Testimonial />
      <PartnerSection />
      <FaqSection />
      <CallToAction />
    </>
  )
 
}

export default Solutions
