import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import parse from 'html-react-parser';
import { Icon } from 'react-icons-kit';
import React, { Fragment } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Card from '../../common/components/Card';
import Image from '../../common/components/Image';
import Heading from '../../common/components/Heading';
import Container from '../../common/components/UI/Container';
import FeatureBlock from '../../common/components/FeatureBlock';

import InfoSectionWrapper from './info.style';
import { androidArrowForward } from 'react-icons-kit/ionicons/androidArrowForward';

const InfoSection = ({
  row,
  col,
  title,
  description,
  button,
  textArea,
  imageArea,
  imageAreaRow,
  imageWrapper,
  imageWrapperOne,
  imageWrapperTwo,
  textAreaRow,
  iconStyle,
  blockStyle,
  showMoreStyle,
  iconTitleStyle,
  iconDescriptionStyle
}) => {

  const DATA = useStaticQuery(graphql`
    query {
      dataJson {
        SOLUTIONS {
          sectionTitle
          sectionDescription
          imageOne {
            childImageSharp{
              fluid{
                src
              }
            }
          }
          imageOneAlt
          imageTwo {
            childImageSharp{
              fluid{
                src
              }
            }
          }
          imageTwoAlt
          items {
            id
            title
            description
            path
            icon {
              publicURL
            }
          }
        }
      }
    }
  `);

  const { sectionTitle,
    sectionDescription,
    imageOne,
    imageOneAlt,
    imageTwoAlt,
    imageTwo,
    items 
  } = DATA.dataJson.SOLUTIONS

  return (
    <InfoSectionWrapper>
      <Container fullWidth noGutter className="info-sec-container sectionBreakPoint">
        <Box {...row} {...imageAreaRow}>
          <Box {...col} {...imageArea}>
            <Card {...imageWrapper} {...imageWrapperOne}>
              <Fade left>
                <Image src={imageOne.childImageSharp.fluid.src} alt={imageOneAlt} />
              </Fade>
            </Card>
            <Card {...imageWrapper} {...imageWrapperTwo}>
              <Fade bottom>
                <Image src={imageTwo.childImageSharp.fluid.src} alt={imageTwoAlt} />
              </Fade>
            </Card>
          </Box>
        </Box>
      </Container>
      <Container>
        <Box {...row} {...textAreaRow}>
          <Box {...col} {...textArea} className="power">
            <Heading
              {...title}
              content={sectionTitle}
            />
            <Text
              {...description}
              content={parse(sectionDescription)}
            />
            {items.map((item) => (
              <Fade>
                <Link to={item.path} style={{ 'color': 'inherit' }}>
                  <FeatureBlock {...blockStyle}
                    key={`wallet--key${item.id}`}
                    iconPosition="left"
                    iconStyle={iconStyle}
                    icon={
                      <Fragment>
                        <Image src={item.icon.publicURL} alt={item.title} />
                      </Fragment>
                    }
                    title={<Heading as="h3" content={item.title} {...iconTitleStyle} />}
                    description={<Text content={item.description} {...iconDescriptionStyle} />}
                    contentStyle={ { 'paddingLeft': '30px', 'marginTop': 'auto', 'marginBottom': 'auto' } }
                    btnWrapperStyle={{'display': 'none'}}
                    button={
                      <p {...showMoreStyle}>
                        Ver más <Icon icon={androidArrowForward} /> 
                      </p>
                    }
                  />
                </Link>
              </Fade>
            ))}

          </Box>
        </Box>
      </Container>
    </InfoSectionWrapper>
  );
};

InfoSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
  imageAreaRow: PropTypes.object,
  imageWrapper: PropTypes.object,
  imageWrapperOne: PropTypes.object,
  imageWrapperTwo: PropTypes.object,
  textAreaRow: PropTypes.object,
};

InfoSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  textAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '50%', '50%', '42%'],
  },
  imageArea: {
    width: ['100%', '100%', '50%', '50%', '55%'],
    flexBox: true,
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  imageWrapperOne: {
    mr: ['-250px', '-250px', '-200px', '-250px', '-400px'],
  },
  imageWrapperTwo: {
    alignSelf: 'flex-end',
    mb: '-60px',
  },
  title: {
    fontSize: ['35px', '28px', '31px', '35px', '43px'],
    fontWeight: 'bolder',
    color: 'headingColor',  
    letterSpacing: '-0.025em',
    mb: '20px',
    lineHeight: '1.1',
  },
  description: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '1.75',
    mb: '33px',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'secondaryWithBg',
  },
  iconStyle: {
    width: '70px',
    height: '70px',
    borderRadius: '20%',
    bg: '#fae597',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    color: '#ffffff',
    overflow: 'hidden',
    flexShrink: 0,
    padding: '5px'
  },
  blockStyle: {
    mt: '25px'
  },
  showMoreStyle: {
    paddingTop: '2px !important',
    textAlign: 'left',
  },
  iconTitleStyle: {
    textAlign: 'left',
    fontWeight: 'bold',
    mb: '5px'
  },
  iconDescriptionStyle: {
    textAlign: 'left',
    marginBottom: '20px',
    color: 'textColor',
    mb: '0'
  },
};

export default InfoSection;
